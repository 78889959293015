
.pageWrapper {
  min-height: 100vh;
}

.contentArea {
  flex-grow: 1;
  max-width: 1300px;
  margin: 0 auto;
}

.card {
  box-shadow: $card-box-shadow;
  margin-bottom: 30px;
  background-color: $white;
}

.list-group-item {
  background-color: $white !important;
}

.circle-box {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 100%;

  &.lg-box {
    width: 60px;
    height: 60px;
    font-size: 21px;
    line-height: 60px;
  }
}

.button-group .btn {
  margin: 3px;
}

table {
  width: 100%;
  th,
  td {
    background-color: transparent !important;
    padding: 8px 12px;
  }

  th {
    font-weight: 500;
  }
  thead tr{
    background-color: $gray-400;
  }
  tbody tr {
    border-bottom: 1px solid $gray-300;
    &:hover {
      background-color: $gray-300;
    }
  }

}

.dropdown-menu {
  background-color: $white !important;
}

body h1,h2,h3,h4,h5,h6{
  margin: 0;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 90%;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.btn-group{
  display: flex;
  justify-content: center;
  gap: 20px;    
}
.popup-content h5 {
  margin: 20px auto;
  font-size: 18px;
}


.delete-btn {
  background-color: #ff4d4f;
  color: #fff;
  height: 36px;
  border-radius: 6px !important;

  &:hover {
    background-color: #f34e51;
    color: #fff;
    height: 36px;
    border-radius: 6px !important;

  }
}

.cancel-btn {
  background-color: #d9d9d9;
  color: #333;
  height: 36px;
  border-radius: 6px !important;
  &:hover {
    color: #333;
    height: 36px;
    border-radius: 6px !important;
    background-color: #e2dbdb;
  }
}

.h-screen {
  height: 100vh;
}
.login-container{
  background: #eeee;
    padding: 40px;
    border-radius: 8px;
max-width: 540px;
width: 100%;
h6{
  font-size: 30px;
  font-weight: 600;
}
img{
  max-width: 300px;
  height: auto;
  width: fit-content;
  margin: 0 auto;
  display: block;
}
}
.end-5{
  right: 10px !important;
}

.table-image{
  width: 60px;
  height: 60px;
  object-fit: contain;
  display: block;
  border-radius: 50%; 
  border: 1px solid $gray-300;

}
.table-title{
  max-width: 300px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.table-description{
  max-width: 480px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.table-nameContact{
 max-width: 230px;
  width: fit-content;
  font-size: 14px;
  text-wrap: wrap;
  word-wrap: break-word;
}
.table-comments{
   max-width: 300px;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.table-comments-contact{
  max-width: 300px;
 width: fit-content;
 font-size: 14px;
}
.text-red-500{
  color: $danger !important;
}
.text-green-500{
  color: $success !important;
}
.popup-message{
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  background: $success;
  border-radius: 6px;
  padding: 12px;
  min-width: 250px;
position: absolute;
bottom: 10px;
left: 10px;
}
.mb-2{
margin-bottom: 10px;
}
.text-center{
  text-align: center;
} 
.text-base{
  font-size: 18px;
}
.mt-3{
  margin-top: 12px;
}
.pagination-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  button{
    border: $primary 1px solid;
    border-radius: 4px;
    padding: 5px 10px;
    color: $primary;
    cursor: pointer;
    background-color: transparent;
    &:hover{
      background-color:$primary ;
      color: #fff;
    }

  }
}
@media (max-width: 1279px) {
  .table-title{
    max-width: 200px;
  }
  .table-description{
    max-width: 222px;
  }
  .table-comments{
      max-width: 120px;
  }
  .table-nameContact{
    max-width: 120px;
  }
  .responsive-table{
    font-size: 14px !important;
  }
  table th, table td{
    padding: 5px ;
  }
}
@media (max-width: 1023px) {
  .table-title, .table-description, .table-comments, .table-nameContact{
    max-width: 94%;
  }
  .table-comments-contact{
    max-width: 94%;
   width: fit-content;
  }
  .responsive-table thead {
    display: none;
  }
  .responsive-table tbody tr {
    display: grid;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .responsive-table tbody tr th {
    text-align: left;
    padding-left: 270px;
    position: relative;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .responsive-table tbody tr td {
    text-align: left;
    padding-left: 270px;
    position: relative;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .responsive-table tbody tr td div,
  .responsive-table tbody tr td svg,
  .responsive-table tbody tr td ul {
    margin: 0;
  }
  .responsive-table tbody tr td ul {
    display: flex;
    justify-content: flex-start;
  }
  .responsive-table tbody tr th::after {
    position: absolute;
    content: attr(title);
    top: 7px;
    left: 44px;
    width: 178px;
    font-size: 14px;
    text-transform: uppercase;
  }
  .responsive-table tbody tr td::after {
    position: absolute;
    content: attr(title);
    top: 8px;
    left: 44px;
    width: 178px;
    font-size: 14px;
    text-transform: uppercase;
  }
}


@media (max-width: 767px) {
  .container-fluid {
    flex-wrap: nowrap !important;
  }
 
}
